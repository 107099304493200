<template>
    <div>
        <v-tooltip bottom :disabled="!props.hasTooltip" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn  :outlined="props.outlined" :to="{ name: props.to }" v-on="on" v-bind="attrs" :disabled="props.disabled" :class="`reset-width ${props.iconClass}`" :loading="props.loading" @click="props.click" width="40" :color="props.color"  elevation="0" small :height="props.height">
                    <!-- normal icons mdi -->
                    <v-icon v-if="!props.customIcon" :color="props.iconColor" >{{props.icon}}</v-icon>
                    <!-- slot custom icon -->
                    <slot name="imgIcon"></slot>
                </v-btn>
            </template>
        <span class="text-center caption">{{props.tooltip}}</span>
        </v-tooltip>
    </div>
</template>
<script>

    export default {
        name: 'iconButton',
        props: ['props'],
       
    }
</script>

<style lang="scss">
    .reset-width{
        min-width: unset !important;
    }
    .transparentBackground.v-btn:before{
        background-color: transparent !important;
    }
   
   
</style>