<template>
    <div class="radio__full-Container">
        <div class="radio__container rounded">
            <p v-if="title != ''" class="radio__text-title">{{title}}</p>
            <div  class="radio__container-input">
                <v-radio-group v-model="value">
                   <div class="radio__inputs">
                    
                        <v-radio
                            v-for="(item, index) in radiosData" :key="index"
                            :class="`mb-0 mr-${item.mr} radio__input`"
                            :label="`${item.label}`"
                            :value="item.value"
                        ></v-radio>
                   
                   </div>
                </v-radio-group>
            </div>
       
        </div>
        <p v-if="errorMessages != ''" class="error--text px-0 py-0 mx-0 my-0">{{errorMessages}}</p>
    </div>
    

</template>
<script>
export default {
    props: ['valueInput', 'title', 'errorMessages', 'radiosData'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        }
    }
}
</script>

<style lang="scss">
    
    
    .radio{
        &__inputs{
            display: flex;
        }

        &__input{
            label{
                font-size: .9rem !important;
                margin-top: 5px !important;
            }
        }

        margin-top: 18px !important;

        &__full-Container{

        }
        &__container{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: var(--v-inputsBackground-base);
                height: 40px;
        }

        &__container-input{
            display: flex;
            align-items: center;
            
        }

        &__switch-cont{
            display: flex;
            align-items: center;
        }

        &__text{
                &-title{
                    margin-bottom: 0 !important;
                    padding: 2px 10px;
                }
                &-left{
                    padding: 2px 10px;
                }
                &-right{
                    padding: 2px 10px 2px 5px;
                }
        }
    }
</style>